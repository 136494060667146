var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"rC66OeVJk2QaFnvHj8yaY"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.MACHINE === 'production') {
    Sentry.init({
        dsn: SENTRY_DSN || 'https://5f1f5671236d4e53904492ace8e02e0c@o956507.ingest.sentry.io/5905874',
        // Percentage or perf monitoring traces
        // tracesSampleRate: 0,
        tracesSampler: () => 0.0,
        // allowUrls: [/https?:\/\/portal\.gitnation\.org/],
        ignoreErrors: [
            // Random plugins/extensions
            'top.GLOBALS',
            'TypeError: Failed to fetch',
            'TypeError: NetworkError when attempting to fetch resource.',
            'TypeError: Cancelled',
            'Error: No error message',
            'AbortError: The operation was aborted.',
            'UnhandledRejection: Non-Error promise rejection',
            'AuthenticationError: You must be logged in to access this',
            // hydration errors
            'Hydration failed because the initial UI does not match what was rendered on the server.',
            'This Suspense boundary received an update before it finished hydrating.',
            'Text content does not match server-rendered HTML.',
            'https://reactjs.org/docs/error-decoder.html?invariant=418', // Hydration failed because the initial UI does not match what was rendered on the server.
            'https://reactjs.org/docs/error-decoder.html?invariant=421', // This Suspense boundary received an update before it finished hydrating.
            'https://reactjs.org/docs/error-decoder.html?invariant=425', // Text content does not match server-rendered HTML.
            'coinbase', // some extensions, like injectCoinbaseWalletDappProvider

            // TODO: need to investigate why this error occurs, but we ignore it for Sentry due to noise
            'There was an error while hydrating this Suspense boundary. Switched to client rendering.',
            'There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root will switch to client rendering.',
            'https://reactjs.org/docs/error-decoder.html?invariant=422', // There was an error while hydrating this Suspense boundary. Switched to client rendering.
            'https://reactjs.org/docs/error-decoder.html?invariant=423', // There was an error while hydrating. Because the error happened outside of a Suspense boundary, the entire root...
        ],
        denyUrls: [
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            /^chrome-extension:\/\//i,
            /static\.cloudflareinsights\.com/i,
        ],
    });
}
